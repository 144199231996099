<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />

      <BaseBanner
        :description="$t('generic_sign_up_eligible_sign_up_banner_subtitle')"
        :title="$t('generic_sign_up_eligible_sign_up_banner_title')"
        scroll-into-view
      />

      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('generic_sign_up_with_kaia')"
        />
        <p class="kds-typography-paragraph-large">
          {{ $t('generic_sign_up_personal_info_text') }}
        </p>
      </div>

      <BaseBanner
        v-if="showLoginBanner"
        scroll-into-view
        variant="important"
      >
        <p
          class="kds-typography-paragraph-large"
          v-html="
            $t('b2b__verification__warning_description_with_support_email', {
              VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL,
            })
          "
        />
        <template #actions>
          <BaseButton
            data-qa="login"
            @click="router.push({ name: ROUTE.B2B_LOGIN })"
          >
            {{ $t('generic_login_with_existing_account') }}
          </BaseButton>
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="errorBannerData"
        :title="$t(errorBannerData.title)"
        scroll-into-view
        variant="warning"
      >
        <div v-html="errorBannerData.text" />
      </BaseBanner>

      <form
        id="signup_form"
        class="kds-content-block"
      >
        <TextField
          v-if="showPersonalInformation"
          :label="$t('b2b_sign_up_name_label')"
          autocomplete="given-name"
          data-qa="first-name"
          v-bind="fieldAttrs.firstName"
        />

        <TextField
          v-if="showPersonalInformation"
          :label="$t('b2b_sign_up_last_name_label')"
          autocomplete="family-name"
          data-qa="last-name"
          v-bind="fieldAttrs.lastName"
        />

        <DateOfBirthField
          v-if="showPersonalInformation"
          v-bind="fieldAttrs.dateOfBirth"
        />

        <TextField
          :label="$t('generic_field_email')"
          autocomplete="email"
          data-qa="email"
          type="email"
          v-bind="fieldAttrs.email"
        />

        <PhonenumberField
          :country="country"
          :label="$t('b2b_sign_up_phone_number_label')"
          v-bind="fieldAttrs.phoneNumber"
        />

        <PasswordField
          autocomplete="new-password"
          data-qa="password"
          show-password-requirements
          v-bind="fieldAttrs.password"
        />

        <BaseBanner variant="info">
          <CheckboxField
            data-qa="accept-terms-and-conditions"
            v-bind="fieldAttrs.acceptTerms"
          >
            <DocumentViewer :link="$t('b2b__new_account__accept_terms')" />
          </CheckboxField>
        </BaseBanner>
      </form>

      <AlreadyHaveAccount />
    </div>
    <template #actions>
      <BaseButton
        data-qa="submit"
        form="signup_form"
        v-bind="submitAttrs"
      >
        {{ $t('generic_create_account') }}
      </BaseButton>
    </template>
    <template #footer><FooterLinks v-if="!isFromApp()" /></template>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import usePersistedConfigStore from '@shared/store/usePersistedConfigStore.js'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'petite-vue-i18n'

import {
  getTimezone,
  isDoBKey,
  isEmailKey,
  isFirstNameKey,
  isLastNameKey,
} from '@shared/utils'
import VERIFICATION_METHODS from '@us/app/config/verification-methods.js'
import ROUTE from '@us/app/router/appModule/names'
import Tracker from '@shared/Tracker'

import useForm from '@shared/composables/useForm.js'

import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'

import CorporateHeader from '@shared/components/CorporateHeader.vue'
import AlreadyHaveAccount from '@us/app/components/AlreadyHaveAccount.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import CheckboxField from '@shared/components/form/CheckboxField.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import PasswordField from '@shared/components/form/PasswordField.vue'
import PhonenumberField from '@shared/components/form/PhonenumberField.vue'
import TextField from '@shared/components/form/TextField.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'
import { getEligibilitySessionHash } from '@us/app/state/store.js'
import { isFromApp } from '@shared/helpers/isFromApp.js'
import env from '@shared/env.js'
import FooterLinks from '@us/app/components/FooterLinks.vue'

const { t } = useI18n()
const store = useStore()
const persistedConfigStore = usePersistedConfigStore()
const route = useRoute()
const router = useRouter()

const lang = computed(() => store.getters.lang)
const country = computed(() => {
  return store.getters.country || env.VITE_DEFAULT_COUNTRY_CODE
})
const formData = computed(() => store.getters.formData)
const corporateEligibilityData = computed(
  () => store.getters.corporateEligibilityData,
)

const corporate = computed(() => store.getters.corporate)

const showPersonalInformation = ref(false)
const showLoginBanner = ref(false)
const errorBannerData = ref(null)

const kaiaSubmissionTrackEvent = (success, errorMessage) => {
  Tracker.trackKaiaEvent(
    {
      event_name: 'cb.onbrdg.create_account',
      app_area: 'onboarding',
      action: 'create',
      object_type: 'account',
      source: 'client_browser',
      screen_name: 'onb_account_creation',
    },
    {
      custom_payload: {
        search_success: success,
        corporate_id: corporate.value.id,
        corporate_name: corporate.value.title,
        corporate_type: corporate.value.corporateTypes.toString(),
        corporate_channel: corporate.value.corporateChannel,
        error_message: errorMessage,
        utm_medium: store.getters.utmData.medium,
        utm_source: store.getters.utmData.source,
        utm_campaign: store.getters.utmData.campaign,
        utm_content: store.getters.utmData.content,
      },
    },
  )
}

// searches the corporateEligibilityData and initialQuery for an entry
// where the entries key matches the keyIdentifier condition and returns the
// entries value (null otherwise), used to prefill the form with known data.
const getFromKnownData = (keyIdentifier) =>
  [
    ...Object.entries(corporateEligibilityData.value),
    ...Object.entries(store.getters.initialQuery),
  ].find(([key]) => keyIdentifier(key))?.[1]

const redirectToNext = async () => {
  await router.push({
    name: ROUTE.B2B_DOWNLOAD_LINK,
  })
}

const form = ref({
  firstName: formData.value.firstName || getFromKnownData(isFirstNameKey) || '',
  lastName: formData.value.lastName || getFromKnownData(isLastNameKey) || '',
  dateOfBirth: formData.value.dateOfBirth || getFromKnownData(isDoBKey) || '',
  email: formData.value.email || getFromKnownData(isEmailKey) || '',
  phoneNumber: formData.value.phoneNumber || '',
  password: formData.value.password || '',
  acceptTerms: formData.value.acceptTerms || false,
})

watchEffect(() => {
  store.commit('formData', { ...form.value })
})

const formConfig = computed(() => {
  const config = {}

  if (showPersonalInformation.value) {
    config.firstName = { validations: ['required'] }
    config.lastName = { validations: ['required'] }
    config.dateOfBirth = { validations: ['dob'] }
  }

  config.email = { validations: ['required', 'email'] }
  config.phoneNumber = { validations: ['required', 'phone'] }
  config.password = { validations: ['password'] }
  config.acceptTerms = { validations: ['accepted'] }

  return config
})

const { fieldAttrs, submitAttrs } = useForm(form, formConfig, {
  onSubmit: async () => {
    const emailCode = store.getters.voucher?.emailCode

    const lookupKey =
      corporateEligibilityData.value?.lookupKey || route.query.lookupKey || ''

    // INFO: Uses the url 'auth/sign_up/' which is referenced in the onError block when determining where the error was thrown
    await store.dispatch('signUp', {
      ...form.value,
      phoneNumber: form.value.phoneNumber.split(' ').join(''),
      corporateId: corporate.value.id,
      language: lang.value,
      country: country.value,
      timezone: getTimezone(),
      verificationEmail: emailCode,
      landingPageExperimentVariant:
        persistedConfigStore.landingPageExperimentVariant,
      lookupKey,
      // Directly true for US users, because they don't have to accept the terms separately
      acceptedDataProtection: true,
      acceptedTracking: persistedConfigStore.performanceTrackingEnabled,
      eligibilitySessionHash: getEligibilitySessionHash(),
      fromApp: isFromApp(),
      corporateEligibilityData: corporateEligibilityData.value,
      signupContext: store.getters.signupContext,
    })

    switch (corporateEligibilityData.value.type) {
      case VERIFICATION_METHODS.VOUCHER:
        await store.dispatch('subscribeUserWithVoucher', {
          code: corporateEligibilityData.value.voucher,
        })
        break
      case VERIFICATION_METHODS.ELIGIBLE_TOKEN:
        await store.dispatch(
          'subscribeUserWithEligibleToken',
          corporateEligibilityData.value,
        )
        break
      case VERIFICATION_METHODS.ELIGIBILITY_LIST:
        await store.dispatch(
          'subscribeUserWithVerificationData',
          corporateEligibilityData.value,
        )
        break
      default:
        // unsupported signup method
        console.warn(
          'UNSUPPORTED_SUBSCRIPTION_METHOD_AT_SIGNUP',
          corporate.value.preferredSignupMethod,
          corporateEligibilityData.value.type,
        )
    }
  },
  onSuccess: async () => {
    kaiaSubmissionTrackEvent(true)

    await redirectToNext()
  },
  onError: (error) => {
    errorBannerData.value = null
    kaiaSubmissionTrackEvent(false, error)

    const status = error?.response?.status
    const errorKey =
      error?.response?.data?.error ?? error?.response?.data?.errors?.[0]
    const url = error?.response?.config?.url

    switch (url) {
      case 'v2/auth/sign_up/': {
        switch (status) {
          case 403:
            showLoginBanner.value = true
            break
          default:
            console.error(error)
            errorBannerData.value = {
              title: t('generic__error_title'),
              text: t('generic__error_message', {
                email: env.VITE_SUPPORT_EMAIL,
              }),
            }
        }

        break
      }
      // subscription errors
      default: {
        const { user } = store.getters

        switch (true) {
          case status === 400 && errorKey === 'ALREADY_SUBSCRIBED': // uhchub + eligibility list
            // TODO handle success
            redirectToNext()
            break
          case status === 409 &&
            errorKey === 'CURRENT_SUBSCRIPTION_EXPIRATION_DATE_UNDEFINED': // voucher
            // TODO handle success
            redirectToNext()
            break
          case status === 409 &&
            errorKey === 'CORPORATE_DOES_NOT_ALLOW_RENEWAL': // voucher
          case status === 409 && errorKey === 'ALREADY_USED_THIS_VOUCHER': // voucher
            errorBannerData.value = {
              title: t('b2b__sponsor__label__activation_failed'),
              description: t('b2c_common_error_token_conflict'),
              showDownloadLinkButton: true,
            }
            break
          case corporateEligibilityData.value.type === 'eligible_token':
            // TODO webmd-one errors
            errorBannerData.value = {
              title: t('b2b__sponsor__label__activation_failed'),
              text: t('b2b__uhchub_verification__description'),
            }
            break
          default:
            console.error(
              `creating subscription for user ${user?.id} failed with code ${error?.response?.status}`,
            )
            errorBannerData.value = {
              title: t('b2b__sponsor__label__activation_failed'),
              text: t(
                'b2b__sponsor__label__kaia_pro_failed_to_activate_with_sponsor_huk_with_support_email',
                { VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL },
              ),
            }
        }
      }
    }
  },
})

onMounted(() => {
  Object.keys(formConfig.value).forEach((fieldKey) => {
    if (route.query[fieldKey]) {
      form.value[fieldKey] = route.query[fieldKey]
    }
  })

  showPersonalInformation.value =
    !form.value.firstName || !form.value.lastName || !form.value.dateOfBirth
})
</script>

<style lang="scss">
// TODO: Overwrite password requirements ordering as in the old layout the horizontal space is not enough
.password-input__password-requirements {
  @include grid-container(1fr 1fr, auto, xs);

  @include media-up(xl) {
    @include grid-container(repeat(4, 1fr), auto, md);
  }
}
</style>
