<template>
  <div
    v-if="showDialog"
    class="cookie-dialog"
  >
    <BaseButton
      variant="ghost"
      icon
      size="small"
      class="close-button"
      @click="close"
    >
      <CloseIcon />
    </BaseButton>
    <DocumentViewer
      class="message kds-typography-paragraph-medium"
      :link="$t('cookie_dialog_message')"
    />
    <div class="actions">
      <BaseButton
        variant="primary"
        size="small"
        @click="accept"
      >
        {{ $t('cookie_dialog_accept') }}
      </BaseButton>
      <BaseButton
        variant="ghost"
        size="small"
        @click="showChoices"
      >
        <span class="action_show_choices">
          {{ $t('cookie_dialog_open_preferences') }}
        </span>
      </BaseButton>
    </div>
  </div>
  <CookiePreferencesModal ref="cookiePreferencesModalRef" />
</template>
<script setup>
import { computed, ref } from 'vue'
import CloseIcon from '@shared/assets/icon/CloseIcon.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import usePersistedConfigStore from '@shared/store/usePersistedConfigStore.js'
import DocumentViewer from '@shared/components/DocumentViewer.vue'
import CookiePreferencesModal from '@us/app/components/CookiePreferencesModal.vue'
import { isFromApp } from '@shared/helpers/isFromApp.js'
import { useRoute } from 'vue-router'

const persistedConfigStore = usePersistedConfigStore()
const route = useRoute()

const cookiePreferencesModalRef = ref(null)
const showDialog = computed(
  () =>
    // only render once the route is known
    route.name !== undefined &&
    // only render if it was not shown before
    !persistedConfigStore.cookieDialogShown &&
    //only render if the WC is not wrapped in the app
    !isFromApp(),
)

const close = () => {
  persistedConfigStore.cookieDialogShown = true
}

const accept = () => {
  persistedConfigStore.cookieDialogShown = true
}

const showChoices = () => {
  persistedConfigStore.cookieDialogShown = true
  cookiePreferencesModalRef.value.open()
}
</script>

<style lang="scss" scoped>
.cookie-dialog {
  position: fixed;
  left: $kds-space-2x;
  right: $kds-space-2x;
  bottom: $kds-space-2x;
  box-shadow: 0 4px 8px 6px rgba(0, 0, 0, 0.06);
  z-index: 9997;
  margin: auto;
  border-radius: 16px;
  @include flex-container(column, space-between, sm, flex-end);
  background: $kds-color-background-base;
  padding: $kds-space-2x;
  max-width: $size-large-x;

  .close-button {
    // offset to visual boundary of icon
    margin: -8.5px;

    @include media-up(md) {
      margin: unset;
    }
  }

  .message {
    text-align: center;

    @include media-up(md) {
      text-align: start;
    }

    :global(a) {
      color: $kds-color-text-interactive;
    }
  }

  .actions {
    width: 100%;
    white-space: nowrap;
    @include flex-container(column, space-between, sm, flex-end);

    .action_show_choices {
      text-decoration: underline;
    }

    @include media-up(md) {
      width: auto;
      @include flex-container(row-reverse, space-between, sm, center);
    }
  }

  @include media-up(md) {
    @include flex-container(row, space-between, sm, center);
  }
}
</style>
