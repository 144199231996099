<template>
  <TextField
    :id="name"
    v-model="phoneNumber"
    :errors="errors"
    :has-errors="hasErrors"
    :hint="hint"
    :label="label"
    :name="name"
    type="tel"
    autocomplete="tel"
    v-bind="$attrs"
    :data-qa="dataQa"
    @blur="onBlur"
    @focus="onFocus"
    @input="onInput"
  />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import {
  getExampleNumber,
  parsePhoneNumberWithError,
  AsYouType,
  getCountryCallingCode,
} from 'libphonenumber-js'
import { useI18n } from 'petite-vue-i18n'
import examples from 'libphonenumber-js/mobile/examples'
import TextField from '@shared/components/form/TextField.vue'

const { t } = useI18n()
const emit = defineEmits(['blur', 'input', 'focus'])

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  country: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  dataQa: {
    type: String,
    default: 'phone-number',
  },
})

const isFocused = ref(false)

const formatInput = (val) => {
  try {
    const parser = parsePhoneNumberWithError(val, props.country)

    if (!parser.isPossible()) {
      throw new Error()
    }
    return parser.formatInternational({ nationalPrefix: true })
  } catch {
    return new AsYouType().input(val)
  }
}

const phoneNumber = defineModel({
  type: String,
  default: '',
  set: formatInput,
})

const countryCallingCode = computed(
  () => `+${getCountryCallingCode(props.country)}`,
)

const label = computed(() => {
  if (props.country && !!phoneNumber.value?.length && isFocused.value) {
    return [
      t('b2b_sign_up_phone_example_prefix'),
      getExampleNumber(props.country, examples).formatInternational(),
    ].join(' ')
  }

  return props.label
})

const onBlur = () => {
  isFocused.value = false

  if (phoneNumber.value === countryCallingCode.value) {
    phoneNumber.value = ''
  }

  emit('blur', { countryCode: props.country })
}

const onFocus = () => {
  isFocused.value = true

  if (phoneNumber.value === '') {
    phoneNumber.value = countryCallingCode.value
  }

  emit('focus', { countryCode: props.country })
}

const onInput = (e) => {
  // Update the input element's value directly
  e.target.value = formatInput(e.target.value)
  emit('input', { countryCode: props.country })
}

onMounted(() => {
  try {
    const parser = parsePhoneNumberWithError(phoneNumber.value, props.country)

    if (parser.isPossible()) {
      phoneNumber.value = parser.formatInternational({ nationalPrefix: true })
    }
  } catch {
    // do nothing
  }
})
</script>
