import { ref, watchEffect } from 'vue'
import { defineStore } from 'pinia'
import env from '@shared/env.js'

const usePersistedConfigStore = defineStore(
  'persistedConfig',
  () => {
    const performanceTrackingEnabled = ref(/** @type { boolean } */ env.isUs)
    const cookieDialogShown = ref(/** @type { boolean } */ false)
    const landingPageExperimentVariant = ref(/** @type { string|null } */ null)

    return {
      performanceTrackingEnabled,
      cookieDialogShown,
      landingPageExperimentVariant,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  },
)

export default usePersistedConfigStore
