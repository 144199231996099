export default {
  VOUCHER: 'voucher',
  //EMAIL: 'email',
  ELIGIBILITY_LIST: 'eligibility_list',
  //PARTICIPATION_ID: 'participation_id',
  SOLERA: 'solera',
  VIRGINPULSE_SSO: 'virginpulse_sso',
  UHCHUB: 'uhchub',
  WEBMD_ONE: 'webmd-one',
}
