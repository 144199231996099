<template>
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <div class="kds-content-block">
        <p class="kds-typography-display-small">
          {{ $t('b2b__saml_sso_signup_error__title') }}
        </p>
        <p class="kds-typography-paragraph-large">{{ errorMessage }}</p>
      </div>
      <BaseButton
        :text="$t('generic__try_again')"
        @click="redirectToStart"
      />
      <div
        class="kds-typography-paragraph-large"
        v-html="
          $t('b2b__saml_sso_signup_error__contact_support_with_support_email', {
            VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL,
          })
        "
      />
    </div>
    <template #footer><FooterLinks v-if="!isFromApp()" /></template>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import env from '@shared/env'
import ROUTE from '@us/app/router/appModule/names'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import { useI18n } from 'petite-vue-i18n'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BaseButton from '@shared/components/BaseButton.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import FooterLinks from '@us/app/components/FooterLinks.vue'
import { isFromApp } from '@shared/helpers/isFromApp.js'

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const router = useRouter()

const errorMessage = computed(() => {
  switch (route.query?.code) {
    case 'UNAUTHORIZED':
      return t('b2b__saml_sso_signup_error__unauthorized')
    case 'UNKNOWN_SPONSOR_ID':
      return t('b2b__personify_health_signup_error__unknown_sponsor_id')
    case 'NO_ACTIVE_SUBSCRIPTION':
      return t('b2b__saml_sso_signup_error__no_active_subscription')
    case 'DATA_ERROR':
    case 'SERVICE_ERROR':
    default:
      return t('b2b__saml_sso_signup_error__unknown_error')
  }
})

const redirectToStart = () => {
  router.push({
    name: ROUTE.B2B_INFORMATION,
    params: {
      corporate_key: store.getters.corporate.key,
    },
  })
}
</script>

<style lang="scss"></style>
