<template>
  <div class="simple_start_journey">
    <div class="info_section">
      <div class="header">
        <SimpleLandingLogo :corporate="corporate" />
      </div>
      <div class="content kds-content-block">
        <div class="title">
          <h2 class="title--primary">
            {{ $t('b2b__simple_landing_page__start_journey__title') }}
          </h2>
          <h2 class="title--secondary">
            {{ $t('b2b__simple_landing_page__start_journey__slogan') }}
          </h2>
        </div>
        <span class="description">
          {{ $t('b2b__simple_landing_page__start_journey__description') }}
        </span>
        <BaseButton
          :text="ctaBtnTitle"
          @click="handleCTAButtonClick"
        />
      </div>
      <div class="footer">
        <FooterLanguage />
        <FooterLinks slim />
      </div>
    </div>
    <div class="banner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import SimpleLandingLogo from '@us/app/components/SimpleLandingLogo.vue'
import Corporate from '@shared/data/models/corporate.js'
import FooterLinks from '@us/app/components/FooterLinks.vue'
import FooterLanguage from '@us/app/components/FooterLanguage.vue'

export default {
  name: 'SimpleStartJourney',
  components: {
    FooterLanguage,
    FooterLinks,
    BaseButton,
    SimpleLandingLogo,
  },
  props: {
    ctaBtnTitle: {
      type: String,
      default: '',
    },
    corporate: {
      type: Corporate,
      default: null,
    },
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    isGerman: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['lang', 'corporate']),
  },
}
</script>

<style lang="scss" scoped>
.simple_start_journey {
  width: 100%;
  background-color: $kds-color-background-base;

  @include media-up(lg) {
    @include grid-container(1fr 1fr, auto, 0);
    grid-template-areas: 'info banner';
  }

  .info_section {
    grid-area: info;
    min-height: 100dvh;
    @include flex-container(column, space-between, 0);

    .header {
      padding: $kds-space-2_5x;
    }

    .content {
      padding: 0 $kds-space-2_5x;
      flex-grow: 1;
      justify-content: center;

      .title {
        &--primary {
          @include kds-typography-display-medium();

          @include media-up(md) {
            @include kds-typography-display-large();
          }
        }

        &--secondary {
          color: $kds-color-text-interactive-accent;
          @include kds-typography-display-small();

          @include media-up(md) {
            @include kds-typography-display-medium();
          }
        }
      }

      .description {
        @include kds-typography-title-medium(
          $kds-typography-font-weight-regular
        );

        @include media-up(md) {
          @include kds-typography-title-large(
            $kds-typography-font-weight-regular
          );
        }
      }
    }
  }

  .banner {
    display: none;

    @include media-up(lg) {
      grid-area: banner;
      width: 100%;
      min-height: 240px;
      display: block;
      background: url('@us/app/assets/img/thomas-male-banner.jpg') center top
        no-repeat;
      background-size: auto 100%;
    }
  }
}
</style>
