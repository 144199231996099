<template>
  <FormField
    :has-focus="state && hasFocus"
    :is-active="isActive"
    :is-invalid="!state"
    :is-filled="isFilled"
    :error-message="errorMessage"
  >
    <label
      v-if="inputLabel"
      :for="inputName"
      class="form-field__label"
      >{{ inputLabel }}</label
    >
    <input
      :id="id"
      ref="input"
      v-model="value"
      :name="inputName"
      :type="type"
      :data-qa="dataQA"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :content-desc="contentDesc"
      class="form-field__input js-form-field__input"
      :aria-label="ariaLabel"
      :role="role"
      :autocomplete="autocomplete ? '' : 'off'"
      @animationstart="checkAnimation"
      @blur="onBlur"
      @focus="onFocus"
      @keyup="onKeyUp"
      @change="onChange"
      @input="onInput"
      @click="onClick"
    />
    <div
      v-if="visibility"
      :class="[
        'form-field__visibility',
        { 'form-field__visibility-visible': !hiddenValue },
      ]"
      @click="toggleVisibility"
    />
    <div
      v-if="loading"
      class="form-field__loading js-test-input-loading"
    >
      <Spinner />
    </div>
  </FormField>
</template>

<script>
import { supportsTouch } from '@shared/utils'
import FormField from '@shared/components/FormField.vue'
import Spinner from '@shared/components/Spinner.vue'

export default {
  name: 'TextInput',
  components: {
    Spinner,
    FormField,
  },
  inheritAttrs: false,
  props: {
    ariaLabel: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    contentDesc: {
      type: String,
      default: null,
    },
    dataQA: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    focusedCSSClass: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: '',
    },
    invalidCSSClass: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: true,
    },
    visibility: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'change', 'focus', 'blur', 'keyup', 'click'],
  data() {
    return {
      hiddenValue: this.visibility,
      hasFocus: !!this.autofocus,
      isActive: false,
      value: this.defaultValue,
      previousDefaultValue: this.defaultValue,
    }
  },
  computed: {
    type() {
      if (this.inputType === 'email') {
        return 'email'
      }
      if (this.inputType === 'number') {
        return 'number'
      }
      return this.hiddenValue ? 'password' : 'text'
    },
    isFilled() {
      return (
        (this.value != null && this.value !== '') ||
        (typeof this.placeholder === 'string' && this.placeholder.length > 0)
      )
    },
  },
  mounted() {
    if (this.value) {
      this.isActive = true
    }
    if (this.autofocus && !supportsTouch()) {
      this.$refs.input.focus()
    }
  },
  updated() {
    if (this.previousDefaultValue !== this.defaultValue) {
      this.value = this.defaultValue
      this.previousDefaultValue = this.defaultValue
    }

    if (this.value) {
      this.isActive = true
    }
  },
  methods: {
    isFocused(el) {
      return window.document.activeElement === el
    },
    toggleVisibility() {
      this.hiddenValue = !this.hiddenValue
    },
    checkAnimation(e) {
      if (e.animationName === 'onAutoFillStart') {
        this.isActive = true
      } else if (e.animationName === 'onAutoFillCancel') {
        if (!this.hasFocus && this.value === '') {
          this.isActive = false
        }
      }
    },
    onBlur(event) {
      this.$emit('blur', {
        name: this.inputName,
        value: this.value,
        event,
      })
      this.isActive = false
      this.hasFocus = false
    },
    onInput(event) {
      this.$emit('input', {
        name: this.inputName,
        value: this.value,
        event,
      })
    },
    onFocus(event) {
      this.$emit('focus', {
        name: this.inputName,
        value: this.value,
        event,
      })
      this.isActive = true
      this.hasFocus = true
    },
    onChange(event) {
      this.$emit('change', {
        name: this.inputName,
        value: event.target.value,
        event,
      })
    },
    onKeyUp(event) {
      this.$emit('keyup', event)
    },
    onClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>
