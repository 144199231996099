<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <Spinner v-if="loading" />
      <CorporateHeader v-else />
      <BaseBanner
        v-if="showAlreadyUsedBanner"
        variant="info"
        :title="$t('b2b__verification__already_used_error_title')"
        scroll-into-view
      >
        <p
          class="kds-typography-paragraph-medium"
          v-html="$t('b2b__verification__already_used_error_description')"
        />
        <template #actions>
          <BaseButton
            size="small"
            @click="goToAppLogin"
          >
            {{ $t('b2b__verification__already_used_error_cta_open_app') }}
          </BaseButton>

          <GenericSupportModal
            :subject="$t('b2b__support_modal_subject__login_support')"
          />
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="showGenericErrorBanner"
        variant="warning"
        :title="$t('b2b__verification__warning_header')"
        scroll-into-view
      >
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('b2b__uhchub_verification__description')"
        />
        <template #actions>
          <GenericSupportModal
            :subject="$t('b2b__support_modal_subject__login_support')"
          />
        </template>
      </BaseBanner>
    </div>
    <template #footer><FooterLinks v-if="!isFromApp()" /></template>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import ROUTE from '@us/app/router/appModule/names'

import BaseBanner from '@shared/components/BaseBanner.vue'
import Spinner from '@shared/components/Spinner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import GenericSupportModal from '@us/app/components/GenericSupportModal.vue'
import Tracker from '@shared/Tracker.js'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink.js'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import FooterLinks from '@us/app/components/FooterLinks.vue'
import { isFromApp } from '@shared/helpers/isFromApp.js'

const store = useStore()
const router = useRouter()

const loading = ref(false)
const showAlreadyUsedBanner = ref(false)
const showGenericErrorBanner = ref(false)

const corporate = computed(() => store.getters.corporate)

const kaiaSubmissionTrackEvent = (success, errorMessage) => {
  Tracker.trackKaiaEvent(
    {
      event_name: 'c.lfcycl.submit_eligibility_verification',
      app_area: 'onboarding',
      action: 'submit',
      object_type: 'eligibility_verification',
      source: 'client_browser',
      screen_name: 'onb_eligibility_verification',
    },
    {
      custom_payload: {
        search_success: success,
        corporate_id: corporate.value.id,
        corporate_name: corporate.value.title,
        corporate_type: corporate.value.corporateTypes.toString(),
        corporate_channel: corporate.value.corporateChannel,
        error_message: errorMessage,
        utm_medium: store.getters.utmData.medium,
        utm_source: store.getters.utmData.source,
        utm_campaign: store.getters.utmData.campaign,
        utm_content: store.getters.utmData.content,
      },
    },
  )
}

const goToAppLogin = async () => {
  const deepLink = await assembleKaiaAppDeepLink('goto/screen', {
    target: 'login',
  })
  window.location.assign(deepLink)
}

const redirectToNext = () => {
  router.push({
    name: ROUTE.B2B_SIGN_UP,
    params: {
      // redirect to the corporate identified during the eligibility check
      corporate_key: store.getters.corporateEligibilityData.corporate_key,
    },
  })
}

const onSubmit = async () => {
  showAlreadyUsedBanner.value = false
  showGenericErrorBanner.value = false

  Tracker.trackKaiaEvent(
    {
      event_name: 'c.lfcycl.select_button',
      app_area: 'onboarding',
      action: 'select',
      object_type: 'button',
      source: 'client_browser',
      screen_name: 'onb_eligibility_verification',
    },
    {
      button_label: 'continue',
    },
  )

  await store.dispatch('checkEligibilityWithToken', {
    corporateKey: corporate.value.key,
    eligible_token: store.getters.initialQuery.eligible_token,
  })
}

const onSuccess = async () => {
  kaiaSubmissionTrackEvent(true, null)
  console.info('eligible token verification attempt succeeded')
  redirectToNext()
}

const onError = (error) => {
  kaiaSubmissionTrackEvent(false, error)
  switch (error.response.data?.error) {
    case 'ALREADY_USED':
      console.info(
        'eligible token verification attempt soft failed -> proposing login',
        error,
      )
      showAlreadyUsedBanner.value = true
      break
    case 'MULTIPLE_MATCHES_BUT_UNIQUE_MATCH_REQUIRED':
    case 'NOT_ELIGIBLE':
      // saml handshake was successful, but we could not find them in the eligibility lists
      // these users will get access anyway, and we try to reconcile them manually
      console.info(
        'eligible token verification attempt soft failed -> go to signup anyway',
        error,
      )
      redirectToNext()
      break
    case 'TOKEN_BLANK':
    case 'TOKEN_EXPIRED':
    case 'TOKEN_INVALID':
    case 'TOKEN_INCOMPLETE':
      // user is not coming through saml handshake (token is blank), or the token not valid
      showGenericErrorBanner.value = true
      console.warn('eligible token verification attempt failed', error)
      break
    default:
      showGenericErrorBanner.value = true
      console.error('eligible token verification attempt failed', error)
      throw error
  }
}

onMounted(async () => {
  try {
    loading.value = true
    await onSubmit()
    await onSuccess()
  } catch (e) {
    loading.value = false
    onError(e)
  }
})
</script>
