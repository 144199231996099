import corporateTrackingMap from '@us/app/config/corporate_tracking_map'

const getCorporateTrackingSearchParams = (oldQueryString) => {
  const oldQuery = new URLSearchParams(oldQueryString)
  const rawCorporateKey = oldQuery.get('corporate')
  if (rawCorporateKey === null) return null
  const corporateKey = `${rawCorporateKey}`.toLowerCase()

  const corporateTrackingRecord = corporateTrackingMap[corporateKey]

  const newQuery = {}
  // add initial query to new query
  oldQuery.forEach((value, key) => {
    newQuery[key] = encodeURIComponent(value)
  })

  // if no entry in the tracking map is found, return the original query
  if (!corporateTrackingRecord) return newQuery

  // add / overwrite query params from tracking map
  Object.entries(corporateTrackingRecord.query).forEach(([key, value]) => {
    if (value) newQuery[key] = encodeURIComponent(value)
  })

  // add / overwrite corporate key (and ensure its unset if configured that way)
  if (corporateTrackingRecord.corporate) {
    newQuery.corporate = encodeURIComponent(corporateTrackingRecord.corporate)
  } else {
    delete newQuery.corporate
  }

  return newQuery
}

export default getCorporateTrackingSearchParams
