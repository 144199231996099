<!-- This while file is only for a AB experiment and is supposed to be removed as soon as the experiment is concluded -->
<template>
  <component :is="component" />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import LandingPageMSKSimplifiedBaseline from '@us/app/views/LandingPageMSKSimplifiedBaseline.vue'
import LandingPageMSKReflectiveOnboarding from '@us/app/views/LandingPageMSKReflectiveOnboarding.vue'
import usePersistedConfigStore from '@shared/store/usePersistedConfigStore.js'

const component = ref()

const store = useStore()
const persistedConfigStore = usePersistedConfigStore()
const corporate = computed(() => store.getters.corporate)

const componentToVariantMapper = {
  reflective_onboarding_2022: LandingPageMSKReflectiveOnboarding,
  simplified_baseline_2023_12: LandingPageMSKSimplifiedBaseline,
}

const variantGenerator = () => {
  const variant =
    Math.random() < 0.5
      ? 'reflective_onboarding_2022'
      : 'simplified_baseline_2023_12'
  console.info('landing_page_experiment_variant', variant)
  return variant
}

onMounted(() => {
  // corporates of channel providence health plan (pebb, saif, intel) should not see the experiment
  // (channel is currently not part of the api response)
  if (
    ['pebb', 'saif', 'intel'].includes(corporate.value?.key) ||
    store.getters.initialQuery.variant !== undefined
  ) {
    component.value = LandingPageMSKSimplifiedBaseline
  } else {
    const variant =
      persistedConfigStore.landingPageExperimentVariant ?? variantGenerator()

    component.value =
      componentToVariantMapper[variant] ?? LandingPageMSKReflectiveOnboarding
    persistedConfigStore.landingPageExperimentVariant = variant
  }
})
</script>
