<template>
  <div
    class="toggle-container"
    :class="{ disabled: disabled }"
  >
    <label class="toggle">
      <input
        type="checkbox"
        :checked="modelValue"
        :disabled="disabled"
        :data-qa="dataQa"
        v-bind="$attrs"
        @click.stop
        @change="updateValue"
        @focus="onFocus"
        @blur="onBlur"
      />
      <span
        class="toggle-slider"
        :class="{
          checked: modelValue,
        }"
        @click.stop
      ></span>
    </label>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataQa: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'blur', 'focus'])

defineOptions({
  inheritAttrs: false,
})

const isFocused = ref(false)

const onBlur = (e) => {
  isFocused.value = false
  emit('blur', e)
}

const onFocus = (e) => {
  isFocused.value = true
  emit('focus', e)
}

const updateValue = (event) => {
  emit('update:modelValue', event.target.checked)
}
</script>

<style lang="scss" scoped>
.toggle-container {
  display: inline-block;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  cursor: pointer;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $kds-color-background-accent;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: $kds-color-icon-on-interactive-accent;
  border-radius: 50%;
  transition: all 0.3s ease;
}

/* Selected state */
.toggle-slider.checked {
  background-color: $kds-color-background-interactive-accent;
}

.toggle-slider.checked:before {
  transform: translateX(20px);
}

/* Disabled state */
.disabled .toggle {
  cursor: not-allowed;
  opacity: 0.6;
}

.disabled .toggle-slider {
  background-color: $kds-color-background-interactive-disabled;
}
</style>
