import store from '@us/app/state/store'
import ROUTE from '@us/app/router/appModule/names'
import downmarketLinks from '@us/app/config/b2b-downmarket-links'
import { decodeQuery } from '@shared/utils.js'
import SIGNUP_METHODS from '@us/app/config/signup-methods.js'
import VERIFICATION_METHODS from '@us/app/config/verification-methods.js'
import useAuthStore from '@shared/store/useAuthStore.js'

const defaultSignUpMethod = SIGNUP_METHODS.VOUCHER

const preferredSignUpMethodMap = {
  [SIGNUP_METHODS.VOUCHER]: ROUTE.B2B_VOUCHER,
  [SIGNUP_METHODS.UHCHUB]: ROUTE.B2B_UHCHUB_VERIFICATION,
  [SIGNUP_METHODS.WEBMD_ONE]: ROUTE.B2B_WEBMD_ONE_VERIFICATION,
  [SIGNUP_METHODS.ELIGIBILITY_LIST]:
    ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
  [SIGNUP_METHODS.SOLERA]: ROUTE.B2B_SOLERA_VERIFICATION,
  [SIGNUP_METHODS.VIRGINPULSE_SSO]: ROUTE.SAML_SSO_INITIATED,
}

const getCorporateKeyFromRoute = (route) =>
  (route.params.corporate_key || route.query.corporate || '').toLowerCase()

export default async (routeTo) => {
  const authStore = useAuthStore()

  const redirectTo = (name, qs, params) => ({
    name,
    query: qs || routeTo.query,
    params: params || routeTo.params,
  })

  let corporateKey = getCorporateKeyFromRoute(routeTo)

  // authenticate with one_time_token if available
  if (routeTo.query.one_time_token) {
    try {
      await store.dispatch('loginWithOneTimeAuthToken', {
        token: routeTo.query.one_time_token,
      })
    } catch (e) {
      console.warn(
        'authentication with one_time_token failed, falling back to user login',
        e,
      )
    }
    delete routeTo.query.one_time_token
    // reset the route so that the query gets updated
    return routeTo
  }

  await store.dispatch('storeInitialQuery', decodeQuery(routeTo.query))
  const initialQuery = store.getters.initialQuery

  // handle routes that don't need a corporate object
  switch (routeTo.name) {
    case ROUTE.ENTRY:
    case ROUTE.B2B:
      if (!corporateKey) {
        return redirectTo(ROUTE.B2B_PARTNERS)
      }

      if (downmarketLinks.includes(corporateKey)) {
        return redirectTo(ROUTE.B2B_INFORMATION_GENERIC)
      }

      return redirectTo(
        ROUTE.B2B_INFORMATION,
        {},
        {
          corporate_key: corporateKey,
        },
      )
    case ROUTE.B2B_PARTNERS:
    case ROUTE.B2B_INFORMATION_GENERIC:
    case ROUTE.UHCHUB_INIT:
    case ROUTE.WEBMD_ONE_INIT:
    case ROUTE.PERSONIFY_HEALTH_INIT:
      return true
  }

  let corporate = null
  try {
    corporate = await store.dispatch('ensureCorporate', {
      key: corporateKey,
    })
  } catch {
    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corporate_key: corporateKey,
      },
    )
  }

  if (!corporate.isCustomer) {
    // TODO pass corporate key to partner search and show not customer warning there
    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corporate_key: corporate.key,
      },
    )
  }

  // handle routes that need a corporate object
  switch (routeTo.name) {
    case ROUTE.B2B_DOWNLOAD_LINK:
      if (store.getters.user == null) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }
      return true

    case ROUTE.B2B_INFORMATION:
      // skip the information screen if the corporate has disabled extended onboarding
      if (!corporate.showExtendedOnboarding) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }

      // skip the information screen if its a login flow
      if (
        initialQuery.login === 'true' ||
        initialQuery.skip_information ||
        authStore.isAuthenticated
      ) {
        return redirectTo(
          ROUTE.B2B_SELECT_VERIFICATION_METHOD,
          {},
          {
            corporate_key: corporateKey,
          },
        )
      }
      break

    case ROUTE.B2B_SELECT_VERIFICATION_METHOD: {
      let voucherCode = initialQuery.voucher_code || initialQuery.voucher
      const email = initialQuery.email

      let signUpMethod = corporate.preferredSignupMethod
      if (!Object.values(SIGNUP_METHODS).includes(signUpMethod)) {
        console.error(
          new Error(
            `Invalid preferred signup method ${signUpMethod} for corporate ${corporate.key}, falling back to ${defaultSignUpMethod}`,
          ),
        )

        signUpMethod = defaultSignUpMethod
      }

      return {
        ...routeTo,
        name: preferredSignUpMethodMap[signUpMethod],
        params: {
          corporate_key: corporate.key,
          ...(signUpMethod === SIGNUP_METHODS.VOUCHER &&
            voucherCode && { voucherCode }),
        },
        query: {
          ...routeTo.query,
          ...(email && { email }),
        },
      }
    }

    case ROUTE.SAML_SSO_INITIATED:
      if (store.getters.user) {
        return redirectTo(ROUTE.SAML_SSO_COMPLETED)
      }
      break

    case ROUTE.SAML_SSO_COMPLETED:
      if (!authStore.isAuthenticated) {
        return redirectTo(ROUTE.SAML_SSO_ERROR, { code: 'UNAUTHORIZED' })
      }

      // Block the user from going any further without a valid subscription.
      // This should be removed as soon as subscriptions are handled correctly in the apps.
      if (!store.getters.user?.isPro) {
        return redirectTo(ROUTE.SAML_SSO_ERROR, {
          code: 'NO_ACTIVE_SUBSCRIPTION',
        })
      }

      if (store.getters.user?.profile?.hasAcceptedDataProtection) {
        return redirectTo(ROUTE.B2B_DOWNLOAD_LINK)
      }
      break

    case ROUTE.B2B_SIGN_UP: {
      if (initialQuery.login === 'true' || authStore.isAuthenticated) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }

      if (
        !Object.values(VERIFICATION_METHODS).includes(
          store.getters.corporateEligibilityData.type,
        )
      ) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }
      break
    }

    default:
      break
  }
}
