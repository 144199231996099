<template>
  <div class="landing-layout">
    <LandingStickyHeader
      :cta-btn-title="
        $t('b2b__landing_page__generic__cta_btn__claim_my_benefit')
      "
      :handle-c-t-a-button-click="handleCTAButtonClick"
      :show-sticky-header="showStickyHeader"
    />
    <StartJourneyUS
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__lets_go')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
      :receive-c-t-a-button-client-rect="receiveCTAButtonClientRect"
      :variant="variant"
    />
    <ReclaimingLife
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__title')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
      :variant="variant"
    />
    <RealMembers />
    <Landing3Steps
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__short_title')"
      :handle-c-t-a-button-click="handleCTAButtonClick"
    />
    <FaqModule />
    <Contacts />
    <FooterLanguage />
    <FooterCertificates />
    <FooterLinks />
  </div>
</template>

<script setup>
import { setPageTitle } from '@shared/utils'
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import ROUTE from '@us/app/router/appModule/names'
import Contacts from '@shared/components/Contacts.vue'
import FaqModule from '@shared/components/FaqModule.vue'
import Landing3Steps from '@shared/components/Landing3Steps.vue'
import LandingStickyHeader from '@shared/components/LandingStickyHeader.vue'
import ReclaimingLife from '@shared/components/ReclaimingLife.vue'
import StartJourneyUS from '@us/app/components/StartJourneyUS.vue'
import RealMembers from '@shared/components/RealMembers.vue'
import { useI18n } from 'petite-vue-i18n'
import { useRouter } from 'vue-router'
import FooterLinks from '@us/app/components/FooterLinks.vue'
import FooterCertificates from '@us/app/components/FooterCertificates.vue'
import FooterLanguage from '@us/app/components/FooterLanguage.vue'

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const corporate = computed(() => store.getters.corporate)
const getCTAButtonClientRect = ref(() => {})
const variant = computed(() => store.getters.initialQuery.variant)

const showStickyHeader = () => {
  const rect = getCTAButtonClientRect.value()
  return rect != null && rect.top <= -rect.height / 2
}

const receiveCTAButtonClientRect = (clientRect) => {
  getCTAButtonClientRect.value = clientRect
}

const handleCTAButtonClick = () => {
  if (corporate.value) {
    router.push({ name: ROUTE.B2B_SELECT_VERIFICATION_METHOD })
  } else {
    router.push({ name: ROUTE.B2B_PARTNERS })
  }
}

watch(
  corporate,
  (corporate) => {
    setPageTitle(
      [
        corporate?.title ? `${corporate.title} | ` : '',
        t('b2b__landing_page__generic__page_title'),
      ].join(''),
    )
  },
  { immediate: true },
)
</script>

<style lang="scss">
.landing-layout {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
}
</style>
