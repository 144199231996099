import ROUTE from '@shared/modules/assessment/router/names'
import useAuthStore from '@shared/store/useAuthStore.js'

export default async (routeTo) => {
  const authStore = useAuthStore()
  switch (routeTo.name) {
    case ROUTE.ASSESSMENT_LOGIN:
      return true

    default:
      if (!authStore.isAuthenticated) {
        console.log(
          'Assessment User is not authenticated, redirecting to login',
        )
        return {
          name: ROUTE.ASSESSMENT_LOGIN,
          query: routeTo.query,
        }
      }
  }
}
