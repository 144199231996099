<template>
  <div class="footer-certificates">
    <div class="footer-content">
      <p
        class="footer-label kds-typography-title-medium"
        v-html="$t('b2b_static_certificates_title')"
      />
      <div
        class="certificate medical-device"
        v-html="$t('b2b_static_certificates_registered_medical_device')"
      />
      <div
        class="certificate tuev"
        v-html="$t('b2b_static_certificates_iso_13485')"
      />
      <div
        class="certificate gdpr"
        v-html="$t('b2b_static_certificates_gdpr')"
      />
      <div
        class="certificate hipaa"
        v-html="$t('b2b_static_certificates_hipaa')"
      />
    </div>
  </div>
</template>
<script setup></script>

<style lang="scss" scoped>
.footer-certificates {
  border-top: 1px solid $kds-color-line-subtle;

  .footer-content {
    display: flex;
    padding: $kds-space-2_5x;
    gap: $kds-space-2x;
    flex-direction: column;
    align-items: start;
    max-width: $size-large-x;
    margin: auto;

    .footer-label {
      width: 100%;
    }

    .certificate {
      padding-left: 40px;
      display: flex;
      align-items: center;

      &.medical-device {
        height: 25px;
        background: url('@shared/assets/img/b2b/icon-ce.svg') left center
          no-repeat;
      }

      &.tuev {
        height: 32px;
        background: url('@shared/assets/img/b2b/icon-check-figure.svg') left
          center no-repeat;
      }

      &.gdpr {
        height: 33px;
        background: url('@shared/assets/img/b2b/icon-security.svg') left center
          no-repeat;
      }

      &.hipaa {
        height: 33px;
        background: url('@shared/assets/img/b2b/icon-health.svg') left center
          no-repeat;
      }
    }

    @include media-up(md) {
      column-gap: $kds-space-3x;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    @include media-up(lg) {
      justify-content: space-between;

      .footer-label {
        width: unset;
      }
    }
  }
}
</style>
