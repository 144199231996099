<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div
      v-if="onlySubscribe"
      class="kds-main-wrapper"
    >
      <CorporateHeader :corporate="selectedCorporate" />

      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('generic_subscribe_with_existing_account__title')"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('generic_subscribe_with_existing_account__description')"
        />
      </div>
    </div>

    <form
      v-else
      id="login_form"
      class="kds-main-wrapper"
    >
      <CorporateHeader />

      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('generic_login_with_existing_account')"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="
            $t('b2b_login_welcome_text', { name: selectedCorporate.title })
          "
        />
      </div>

      <BaseBanner
        v-if="loginErrorBanner"
        variant="warning"
        :title="loginErrorBanner.title"
        class="error content"
        scroll-into-view
      >
        <div class="kds-content-block">
          <p class="kds-typography-paragraph-medium">
            {{ loginErrorBanner.description }}
          </p>
          <BaseButton
            v-if="loginErrorBanner.showDownloadLinkButton"
            @click="router.push({ name: ROUTE.B2B_DOWNLOAD_LINK })"
          >
            {{ $t('generic_create_account') }}
          </BaseButton>
        </div>
      </BaseBanner>

      <div class="kds-content-block">
        <p
          class="kds-typography-title-medium"
          v-html="$t('b2b_login_account_block_title')"
        />
        <TextField
          v-bind="fieldAttrs?.email"
          type="email"
          autocomplete="email"
          :label="$t('generic_field_email')"
          data-qa="email"
        />

        <PasswordField
          v-bind="fieldAttrs.password"
          data-qa="password"
          autocomplete="current-password"
        />
      </div>

      <NotRegistered />
    </form>

    <template #actions>
      <BaseButton
        v-bind="submitAttrs"
        data-qa="submit"
        form="login_form"
      >
        {{ $t('generic_login') }}
      </BaseButton>
    </template>
    <template #footer><FooterLinks v-if="!isFromApp()" /></template>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'petite-vue-i18n'

import useForm from '@shared/composables/useForm.js'

import VERIFICATION_METHODS from '@us/app/config/verification-methods.js'
import ROUTE from '@us/app/router/appModule/names'
import Tracker from '@shared/Tracker'

import NotRegistered from '@us/app/components/NotRegistered.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import PasswordField from '@shared/components/form/PasswordField.vue'
import TextField from '@shared/components/form/TextField.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import useAuthStore from '@shared/store/useAuthStore.js'
import env from '@shared/env.js'
import FooterLinks from '@us/app/components/FooterLinks.vue'
import { isFromApp } from '@shared/helpers/isFromApp.js'

const { t } = useI18n()
const store = useStore()
const authStore = useAuthStore()
const router = useRouter()

// persist the corporate the user has selected and use that one instead of the one coming from the user profile
const selectedCorporate = store.getters.corporate
const formData = computed(() => store.getters.formData)
const corporateEligibilityData = computed(
  () => store.getters.corporateEligibilityData,
)

const onlySubscribe = authStore.isAuthenticated
const loginErrorBanner = ref(null)

const kaiaSubmissionTrackEvent = (success, errorMessage) => {
  Tracker.trackKaiaEvent(
    {
      event_name: 'cb.onbrdg.create_account',
      app_area: 'onboarding',
      action: 'create',
      object_type: 'account',
      source: 'client_browser',
      screen_name: 'onb_account_creation',
    },
    {
      custom_payload: {
        search_success: success,
        corporate_id: selectedCorporate.id,
        corporate_name: selectedCorporate.title,
        corporate_type: selectedCorporate.corporateTypes.toString(),
        corporate_channel: selectedCorporate.corporateChannel,
        error_message: errorMessage,
        utm_medium: store.getters.utmData.medium,
        utm_source: store.getters.utmData.source,
        utm_campaign: store.getters.utmData.campaign,
        utm_content: store.getters.utmData.content,
      },
    },
  )
}

const redirectToNext = async () => {
  await router.push({
    name: ROUTE.B2B_DOWNLOAD_LINK,
  })
}

const form = ref(
  onlySubscribe
    ? {}
    : {
        email: formData.value.email || '',
        password: formData.value.password || '',
      },
)

const { fieldAttrs, submitAttrs, submit } = useForm(
  form,
  ref(
    onlySubscribe
      ? {}
      : {
          email: { validations: ['required', 'email'] },
          password: { validations: ['required'] },
        },
  ),
  {
    onSubmit: async () => {
      loginErrorBanner.value = null

      if (!onlySubscribe) {
        await store.dispatch('login', form.value)
        kaiaSubmissionTrackEvent(null, null)
      }

      // when a user is already pro we will still try to create a new subscription.
      // For now this will do nothing on the backend, in the future this might update the subscription
      switch (corporateEligibilityData.value.type) {
        case VERIFICATION_METHODS.VOUCHER:
          await store.dispatch('subscribeUserWithVoucher', {
            code: corporateEligibilityData.value.voucher,
          })
          break
        case VERIFICATION_METHODS.ELIGIBLE_TOKEN:
          await store.dispatch(
            'subscribeUserWithEligibleToken',
            corporateEligibilityData.value,
          )
          break
        case VERIFICATION_METHODS.ELIGIBILITY_LIST:
          await store.dispatch(
            'subscribeUserWithVerificationData',
            corporateEligibilityData.value,
          )
          break
        default:
          console.warn(
            'UNSUPPORTED_SUBSCRIPTION_METHOD_AT_LOGIN',
            selectedCorporate.value.preferredSignupMethod,
            corporateEligibilityData.value.type,
          )
      }
    },
    onSuccess: redirectToNext,
    onError: (error) => {
      const status = error?.response?.status
      const errorKey =
        error?.response?.data?.error ?? error?.response?.data?.errors?.[0]
      const url = error?.response?.config?.url

      switch (url) {
        case 'v2/auth/sign_in/': {
          kaiaSubmissionTrackEvent(false, error)

          switch (true) {
            case status === 401:
              kaiaSubmissionTrackEvent(false, 'no user object after signup')
              // TODO how to handle this (need better copy for wrong credentials)?
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t('b2b__uhchub_verification__description'),
              }
              break
            default:
              console.error(error)
          }
          break
        }
        // subscription errors
        default: {
          const { user } = store.getters

          switch (true) {
            case status === 400 && errorKey === 'ALREADY_SUBSCRIBED': // uhchub + eligibility list
              // TODO handle success
              redirectToNext()
              break
            case status === 409 &&
              errorKey === 'CURRENT_SUBSCRIPTION_EXPIRATION_DATE_UNDEFINED': // voucher
              // TODO handle success
              redirectToNext()
              break
            case status === 409 &&
              errorKey === 'CORPORATE_DOES_NOT_ALLOW_RENEWAL': // voucher
            case status === 409 && errorKey === 'ALREADY_USED_THIS_VOUCHER': // voucher
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t('b2c_common_error_token_conflict'),
                showDownloadLinkButton: true,
              }
              break
            case corporateEligibilityData.value.type === 'eligible_token':
              // TODO webmd-one errors
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t('b2b__uhchub_verification__description'),
              }
              break
            default:
              console.error(
                `creating subscription for user ${user.id} failed with code ${error?.response?.status}`,
                error,
              )
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t(
                  'b2b__sponsor__label__kaia_pro_failed_to_activate_with_sponsor_huk_with_support_email',
                  { VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL },
                ),
              }
          }
          break
        }
      }
    },
  },
)

if (onlySubscribe) {
  submit()
}
</script>
