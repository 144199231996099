<template>
  <div class="quiz-content__inner self-test">
    <div class="self-test__layout">
      <div class="form-container">
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_pain_now_question')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_pain_now_description')"
        />
      </div>
      <ScoreSlider
        :max-score="10"
        :score="getScoreSliderValue"
        :label="getScoreSliderLabel"
        :min-text="$t('b2b_self_test_pain_intensity_no_pain')"
        :max-text="$t('b2b_self_test_pain_intensity_severe_pain')"
        data-q-a="score-slider"
        @change="handleSliderChange"
        @tap="handleSliderChange"
      />
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import ScoreSlider from '@shared/components/ScoreSlider.vue'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'SelfTestPainNow',
  components: {
    ScoreSlider,
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', ['painNow']),
    isValid() {
      return this.painNow >= 0 && this.painNow <= 10
    },
    getScoreSliderValue() {
      return this.painNow === -1 ? 5 : this.painNow
    },
    getScoreSliderLabel() {
      return this.painNow === -1 ? '' : `${this.painNow}`
    },
  },
  created() {
    this.interactiveButtonState = this.isValid ? 'ACTIVE' : 'DISABLED'
  },
  methods: {
    handleSliderChange({ value }) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updatePainNow', value)
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;
</style>
