<template>
  <div class="wrapper">
    <div
      :class="{
        wrapper__container: true,
        error: !!errors.length || hasErrors,
        focused: focused,
      }"
    >
      <div
        v-if="hasSlot['prepend']"
        class="wrapper__container--prepend"
      >
        <slot name="prepend"></slot>
      </div>
      <div class="wrapper__container--field">
        <div
          v-if="hasSlot['label']"
          :class="{
            'wrapper__container--field--label': true,
            'shrink-label': shrinkLabel,
          }"
        >
          <slot name="label" />
        </div>
        <div class="wrapper__container--field--input">
          <slot />
        </div>
      </div>
      <div
        v-if="hasSlot['append']"
        class="wrapper__container--append"
      >
        <slot name="append"></slot>
      </div>
    </div>
    <VerticalShowHideTransition :visible="focused && !errors.length && !!hint">
      <div class="wrapper__hint">
        {{ hint }}
      </div>
    </VerticalShowHideTransition>
    <VerticalShowHideTransition :visible="showErrors && errors.length > 0">
      <div class="wrapper__errors">
        <DangerIcon class="wrapper__errors--icon" />
        <slot name="errors">
          {{ $t(errors[0]) }}
        </slot>
      </div>
    </VerticalShowHideTransition>
  </div>
</template>

<script setup>
import useVueSlots from '@shared/composables/useVueSlots.js'
import DangerIcon from '@shared/assets/icon/DangerIcon.vue'
import VerticalShowHideTransition from '@shared/components/VerticalShowHideTransition.vue'

const { hasSlot } = useVueSlots(['prepend', 'append', 'label'])
defineProps({
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  hint: {
    type: String,
    default: '',
  },
  focused: {
    type: Boolean,
  },
  shrinkLabel: {
    type: Boolean,
    required: true,
  },
})
</script>

<style lang="scss">
.wrapper {
  &__container {
    background-color: $kds-color-background-subtle;
    box-shadow: inset 0 0 0 2px $kds-color-line-on-interactive;

    border-radius: 4px;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &.error {
      box-shadow: inset 0 0 0 2px $kds-color-background-interactive-destructive;

      &.focused {
        box-shadow: inset 0 0 0 3px
          $kds-color-background-interactive-destructive;
      }
    }

    &.focused {
      box-shadow: inset 0 0 0 3px $kds-color-line-focus;
    }

    &--field {
      position: relative;
      width: 100%;

      &--label {
        @include kds-typography-ui-subtle-large;
        color: $kds-color-text-interactive;
        left: 0;
        padding: 13px 16px 11px 16px;

        display: block;
        position: absolute;
        top: 0;
        margin: 0;
        user-select: none;
        transition: all $animation-duration;
        pointer-events: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;

        &.shrink-label {
          @include kds-typography-ui-subtle-small;
          padding: 4px 16px 11px 16px;
        }
      }
    }
  }

  &__errors {
    display: flex;
    @include margin(xs, top);
    @include margin(xs, x);
    @include kaia-typography-generic(12px, 16px, normal);
    color: $kds-color-text-interactive-destructive;

    &--icon {
      margin: auto 5px auto 0;
    }
  }

  &__hint {
    @include margin(xs, top);
    @include padding(xs, x);
    @include kaia-typography-generic(12px, 16px, normal);

    color: $kds-color-text-tertiary;
    overflow: hidden;
  }
}
</style>
