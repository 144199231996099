import ROUTE from '@us/app/router/appModule/names'
import env from '@shared/env.js'

export default [
  {
    path: '/b2b',
    name: ROUTE.B2B,
    component: () => import('@us/app/views/B2B.vue'),
  },
  {
    path: '/b2b/partners',
    name: ROUTE.B2B_PARTNERS,
    component: () => import('@us/app/views/B2BPartners.vue'),
    meta: {
      name: 'onb_employer_search',
    },
  },
  {
    path: '/b2b/information',
    name: ROUTE.B2B_INFORMATION_GENERIC,
    component: () =>
      env.isCopd
        ? import('@us/app/views/LandingPageCOPD.vue')
        : import('@us/app/views/LandingPageMSKSimplifiedBaseline.vue'),
  },
  {
    path: '/b2b/:corporate_key',
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
  },
  {
    path: '/b2b/:corporate_key/information',
    name: ROUTE.B2B_INFORMATION,
    component: () =>
      env.isCopd
        ? import('@us/app/views/LandingPageCOPD.vue')
        : import('@us/app/views/LandingPageMSK.vue'),
  },
  {
    path: '/b2b/:corporate_key/information/check-in',
    name: ROUTE.B2B_SIMPLE_CHECK_IN,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/b2b/:corporate_key/information/plan-outline',
    name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/b2b/:corporate_key/information/testimonial',
    name: ROUTE.B2B_SIMPLE_TESTIMONIAL,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/b2b/:corporate_key/voucher/:voucherCode?',
    name: ROUTE.B2B_VOUCHER,
    component: () => import('@us/app/views/B2BVoucher.vue'),
  },
  {
    path: '/b2b/:corporate_key/eligibility_verification',
    redirect: { name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION },
  },
  {
    path: '/b2b/:corporate_key/eligibility_list',
    name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
    component: () =>
      import('@us/app/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corporate_key/solera-verification',
    name: ROUTE.B2B_SOLERA_VERIFICATION,
    component: () => import('@us/app/views/SoleraVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corporate_key/uhchub-verification',
    name: ROUTE.B2B_UHCHUB_VERIFICATION,
    component: () => import('@us/app/views/EligibleTokenVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corporate_key/webmd-one-verification',
    name: ROUTE.B2B_WEBMD_ONE_VERIFICATION,
    component: () => import('@us/app/views/EligibleTokenVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corporate_key/sign-up',
    name: ROUTE.B2B_SIGN_UP,
    component: () => import('@us/app/views/B2BSignUp.vue'),
    meta: {
      name: 'onb_account_creation',
    },
  },
  {
    path: '/b2b/:corporate_key/login',
    name: ROUTE.B2B_LOGIN,
    component: () => import('@us/app/views/B2BLogin.vue'),
    meta: {
      name: 'onb_signin',
    },
  },
  {
    path: '/b2b/:corporate_key/download-link',
    name: ROUTE.B2B_DOWNLOAD_LINK,
    component: () => import('@us/app/views/DownloadLink.vue'),
    meta: {
      name: 'onb_download_prompt',
    },
  },
  {
    path: '/b2b/:corporate_key/download-instructions',
    redirect: { name: ROUTE.B2B_DOWNLOAD_LINK },
  },
  {
    path: '/b2b/:corporate_key/sso-initiated',
    name: ROUTE.SAML_SSO_INITIATED,
    component: () => import('@us/app/views/SamlSSOInitiated.vue'),
  },
  {
    path: '/b2b/:corporate_key/sso',
    redirect: { name: ROUTE.SAML_SSO_COMPLETED },
  },
  {
    path: '/b2b/:corporate_key/sso-completed',
    name: ROUTE.SAML_SSO_COMPLETED,
    component: () => import('@us/app/views/SamlSSOCompleted.vue'),
  },
  {
    path: '/b2b/:corporate_key/sso-error',
    name: ROUTE.SAML_SSO_ERROR,
    component: () => import('@us/app/views/SamlSSOError.vue'),
  },
  ...(env.isProd
    ? []
    : [
        {
          path: '/uhchub/init',
          name: ROUTE.UHCHUB_INIT,
          component: () => import('@us/app/views/UhchubInit.vue'),
        },
        {
          path: '/webmd-one/init',
          name: ROUTE.WEBMD_ONE_INIT,
          component: () => import('@us/app/views/WebMDOneInit.vue'),
        },
        {
          path: '/personifyhealth/init',
          name: ROUTE.PERSONIFY_HEALTH_INIT,
          component: () => import('@us/app/views/PersonifyHealthInit.vue'),
        },
      ]),
  {
    path: '/:corporate_key',
    name: ROUTE.ENTRY,
    component: () => import('@us/app/views/B2B.vue'),
  },
]
