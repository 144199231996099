import * as snowplowTracker from '@snowplow/browser-tracker'
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger'
import env from '@shared/env'
import { toObjectOfNonNullishEntries } from '@shared/utils'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { watchEffect } from 'vue'
import { isFromApp } from '@shared/helpers/isFromApp.js'
import usePersistedConfigStore from '@shared/store/usePersistedConfigStore.js'

const TRACKING_OPT_OUT_COOKIE_NAME = 'opt-out'
let trackingEnabled = false

export default {
  initSnowplow() {
    snowplowTracker.newTracker(
      'web-checkout-tracker',
      env.VITE_SNOWPLOW_COLLECTOR,
      {
        appId: env.VITE_SNOWPLOW_APP_ID,
        platform: 'web',
        eventMethod: 'post',
        cookieSecure: true,
      },
    )

    snowplowTracker.setOptOutCookie(TRACKING_OPT_OUT_COOKIE_NAME)

    // setup snowplow debugger on all but production builds
    // this allows to see what exactly gets tracked in the browsers js console
    if (!env.isProd) {
      snowplowTracker.addPlugin({ plugin: DebuggerPlugin() })
    }

    const persistedConfigStore = usePersistedConfigStore()
    watchEffect(() => {
      if (persistedConfigStore.performanceTrackingEnabled || isFromApp()) {
        this.enableTracking()
      } else {
        this.disableTracking()
      }
    })
  },
  setUser(user) {
    snowplowTracker.setUserId(user?.id?.toString() || null)

    if (user?.guid != null) {
      datadogRum.setUser({ id: user?.guid })
      datadogLogs.setUser({ id: user?.guid })
    } else {
      datadogRum.clearUser()
      datadogLogs.clearUser()
    }
  },

  trackViewScreen(screenName, options) {
    this.trackKaiaEvent(
      {
        event_name: 'cb.lfcycl.view_screen',
        app_area: 'lifecycle',
        action: 'view',
        object_type: 'screen',
        source: 'web-checkout',
        screen_name: screenName,
      },
      options,
    )
  },

  /**
   * @param payload {Object}
   * @param payload.event_name {string} eg 'cb.onbrdg.submit_initial_assessment'
   * @param payload.app_area {string} eg 'onboarding'
   * @param payload.action {string} eg 'submit'
   * @param payload.object_type {string} eg 'initial_assessment'
   * @param payload.source {string} eg 'client_browser'
   * @param payload.screen_name {string} eg 'onb_finish_congratulations'
   * @param [options] {Object} example:
   * {
   *   custom_payload: { ... free formatted JS object }
   * }
   */
  trackKaiaEvent(payload, options) {
    if (!trackingEnabled) return

    payload = Object(payload) === payload ? payload : {}
    options = Object(options) === options ? options : {}

    if (typeof payload.event_name !== 'string' || !payload.event_name) {
      throw new Error(
        'VueSnowplow#trackKaiaEvent:' +
          ' expected payload.event_name to be non-empty string,' +
          ` ${payload.event_name} got instead`,
      )
    }

    const data = {
      event: {
        schema: 'iglu:com.kaiahealth/kaia_event/jsonschema/1-0-0',
        data: payload,
      },
    }

    const context = []

    if (Object(options.custom_payload) === options.custom_payload) {
      context.push({
        schema: 'iglu:com.kaiahealth/custom_payload/jsonschema/1-0-0',
        data: toObjectOfNonNullishEntries(options.custom_payload),
      })
    }

    if (context.length > 0) {
      data.context = context
    }

    snowplowTracker.trackSelfDescribingEvent(data)
  },

  trackPageView(url) {
    if (!trackingEnabled) return
    snowplowTracker.setCustomUrl(url)
    snowplowTracker.trackPageView()
  },

  enableTracking() {
    console.debug('Tracking enabled')
    trackingEnabled = true
    // delete the opt-out cookie
    document.cookie =
      TRACKING_OPT_OUT_COOKIE_NAME +
      '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  },

  disableTracking() {
    console.debug('Tracking disabled')
    // sadly there is no clear way to disable the tracker alltogether
    trackingEnabled = false
    snowplowTracker.clearUserData()
    snowplowTracker.clearGlobalContexts()
    document.cookie = 'sp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.cookie = TRACKING_OPT_OUT_COOKIE_NAME + '=; Path=/;'
  },

  /**
   * Initialize DataDog for RUM (incl crash reporting) and log collection.
   * Proxy can be configured via environment variables to enable EU-compliant solution.
   */
  initDatadog() {
    const clientToken = env.VITE_DATADOG_CLIENT_TOKEN
    const applicationId = env.VITE_DATADOG_APP_ID
    if (applicationId == null || clientToken == null) return
    // Comment this out if you want DD to be enabled on localhost
    if (window.location.hostname === 'localhost') return

    const commonConfig = {
      clientToken: clientToken,
      site: 'datadoghq.com',
      service: `${env.VITE_DISEASE}-${env.VITE_GEO}-webcheckout`,
      env: env.VITE_MODE,
      version: env.VITE_VERSION,
      proxy: env.VITE_DATADOG_PROXY,
    }
    datadogRum.init({
      ...commonConfig,
      applicationId: applicationId,
      allowedTracingUrls: [
        // trace all kaia domains and subdomains but not the snowplow collector
        /https:\/\/(?!collector\.)(?:.*\.)?kaiahealth\.(de|com)/,
      ],
      trackUserInteractions: true,
      trackResources: !env.isProd,
      trackLongTasks: !env.isProd,
      telemetrySampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: env.isUs ? 100 : 0,
      beforeSend: (event) => {
        if (
          event.error?.resource?.url?.includes('snowplow') ||
          event.resource?.url?.includes('snowplow')
        )
          return false
      },
    })
    datadogLogs.init({
      ...commonConfig,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
      forwardReports: ['intervention', 'deprecation'], // filter out csp_violations
      forwardErrorsToLogs: true,
    })
  },
}
