<template>
  <div class="start-journey">
    <div class="start-journey-wrap">
      <div class="start-journey-logo">
        <div class="start-journey-logo__kaia" />
        <img
          v-if="corporate.logoFile"
          :src="corporate.logoFile"
          :alt="corporate.title"
          class="start-journey-logo__corporate"
        />
        <span
          v-else
          class="start-journey-logo__corporate"
        >
          {{ corporate.title }}
        </span>
      </div>

      <div class="start-journey-info">
        <h2
          class="start-journey-info__header"
          v-html="$t('b2b__landing_page__start_journey__title')"
        />
        <div
          class="start-journey-info__text"
          v-html="$t('b2b__landing_page__start_journey__back__description')"
        />
        <BaseButton
          ref="ctaButtonRef"
          @click="handleCTAButtonClick"
        >
          {{ ctaBtnTitle }}
          <template #append>
            <ArrowRightIcon />
          </template>
        </BaseButton>
      </div>
    </div>
    <div :class="['start-journey-large-banner', env.isCopd ? 'copd' : '']" />
  </div>
</template>

<script setup>
import Corporate from '@shared/data/models/corporate.js'
import { onMounted, ref } from 'vue'
import env from '@shared/env.js'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const props = defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  corporate: {
    type: Corporate,
    default: null,
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  receiveCTAButtonClientRect: {
    type: Function,
    default: () => {},
  },
})

const ctaButtonRef = ref()

onMounted(() =>
  props.receiveCTAButtonClientRect(() =>
    ctaButtonRef.value.$el.getBoundingClientRect(),
  ),
)
</script>

<style lang="scss" scoped>
.start-journey {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 24px 40px;
  background-color: transparent;
  position: relative;

  &-logo {
    position: relative;
    z-index: 1;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;

    &__kaia {
      width: 100px;
      height: 15px;
      background-image: url('@shared/assets/img/kaia_text_logo.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__corporate {
      max-height: 50%;
    }
  }

  &__cta {
    @include kaia-typography-p2($font-weight-semi-bold);
    background-color: $midnight;
    border: none;
    display: block;
    margin: 24px 0;
    padding: 16px 24px 17px;
    width: 272px;
    color: $white;
    cursor: pointer;
    border-radius: 40px;

    &::after {
      content: 'w';
      display: inline-block;
      width: 24px;
      margin-left: 7px;
      background-image: url(@shared/assets/img/short_right_arrow.svg);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 26px 52px;
      color: transparent;
    }

    &:hover,
    &:focus {
      background-color: $midnight-highlight;
    }

    &:active,
    &:visited {
      background-color: $ocean;
    }
  }

  &-info {
    &__header {
      @include kaia-typography-h5($font-weight-bold);
      margin: 0;
    }

    &__text {
      @include kaia-typography-p2($font-weight-normal);
      margin: 13px 0 24px;
    }
  }

  &-large-banner {
    display: block;
    background-image: url('@shared/assets/img/thomas-copd-exercise-4172cr.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-top: 24px;
    height: 327px;
    border-radius: 12px;

    &.copd {
      background-position: top;
      background-image: url('@shared/assets/img/landing-page-main-img-copd.jpg');
    }
  }
}

.start-journey {
  @media (min-width: $size-small) {
    padding-bottom: 32px;
  }

  @media (min-width: $size-medium) {
    padding-right: 54px;
    padding-bottom: 64px;
    padding-left: 54px;
  }

  @media (min-width: $size-large) {
    display: flex;
    justify-content: space-between;
    padding: 60px 84px;
  }

  @media (min-width: $size-large-xx) {
    padding-right: 120px;
    padding-left: 120px;
  }

  &-logo {
    @media (min-width: $size-medium) {
      height: 77px;
    }

    @media (min-width: $size-large) {
      padding: 0 0 35px;
      justify-content: flex-start;
    }

    @media (min-width: $size-large-x) {
      padding-top: 20px;
      padding-bottom: 40px;
    }

    @media (min-width: $size-large-xx) {
      padding-left: 0;
    }

    &__kaia {
      @media (min-width: $size-medium) {
        width: 180px;
        height: 26px;
      }

      @media (min-width: $size-large) {
        width: 89px;
        height: 89px;
        background-image: url('@shared/assets/img/kaia_img_logo.svg');
        margin-right: 47px;
      }
    }

    &__corporate {
      @media (min-width: $size-large) {
        max-height: 65%;
      }
    }
  }

  &-info {
    @media (min-width: $size-large) {
      padding-right: 35px;
      padding-left: 0;
    }

    @media (min-width: $size-large-x) {
      padding-right: 20px;
    }

    @media (min-width: $size-large-xx) {
      padding-left: 0;
      padding-right: 50px;
    }

    @media (min-width: $size-large-xxx) {
      padding-right: 120px;
    }

    &__header {
      @media (min-width: $size-medium) {
        @include kaia-typography-h4($font-weight-bold);
        margin-top: 33px;
      }

      @media (min-width: $size-large) {
        margin-top: 0;
        margin-bottom: 21px;
      }

      @media (min-width: $size-large-x) {
        @include kaia-typography-h3($font-weight-bold);
        padding-right: 60px;
      }

      @media (min-width: $size-large-xx) {
        padding-right: 100px;
      }
    }

    &__text {
      @media (min-width: $size-large-x) {
        margin-bottom: 34px;
      }
    }
  }

  &-wrap {
    @media (min-width: $size-large) {
      flex-basis: 46%;
    }
  }

  &-large-banner {
    @media (min-width: $size-medium) {
      width: 100%;
      height: 680px;
    }

    @media (min-width: $size-large) {
      flex-basis: 54%;
      border-radius: 24px;
      margin-top: 0;
    }
  }
}
</style>
