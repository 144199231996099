export default {
  hwi: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-A',
    },
  },
  'harbison-walker': {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-B2',
    },
  },
  'metlife-family': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-2',
    },
  },
  'for-metlife': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-3a',
    },
  },
  metlifevip: {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-5b',
    },
  },
  lynn: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'hphc-team': {
    corporate: 'hphc',
    query: {
      utm_source: 'poster',
      utm_campaign: 'tv-ad-1',
    },
  },
  'hphc-vip': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  jordans: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'poster',
      utm_campaign: 'tv-ad-1',
    },
  },
  'jordans-vip': {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'dana-farber-vip': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'dana-farber-team': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-2b',
    },
  },
  metlife1: {
    corporate: 'metlife',
    query: {
      utm_source: 'referral',
      utm_campaign: 'case-management-optum',
    },
  },
  met: {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '6a',
    },
  },
  'met-life': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '6b',
    },
  },
  'hp-team': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '2a',
    },
  },
  'team-hphc': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '2b',
    },
  },
  dfci: {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '3a',
    },
  },
  'df-institute': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '3b',
    },
  },
  jf: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '1a',
    },
  },
  'j-team': {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '1b',
    },
  },
  'hwi-hh': {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'referral',
      utm_campaign: 'case-management-highmark',
    },
  },
  lynn1: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'sept-mailer',
    },
  },
  'city-lynn': {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'poster',
      utm_campaign: 'sept-poster',
    },
  },
  alstom1: {
    corporate: 'alstom',
    query: {
      utm_source: 'referral',
      utm_campaign: 'consumermedical',
    },
  },
  hwi1: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'nudge-cards',
      utm_campaign: 'nudge-cards-1',
    },
  },
  fairway1: {
    corporate: 'fairway',
    query: {
      utm_source: 'poster',
      utm_campaign: 'oe-poster',
    },
  },
  saif1: {
    corporate: 'saif',
    query: {
      utm_source: 'referral',
      utm_campaign: 'medical-home-referral',
    },
  },
  jf2: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'poster',
      utm_campaign: 'oct-banner',
    },
  },
  hwihm3: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  hphchm3: {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  cityoflynnhm3: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  jacobs: {
    corporate: 'jacobsengineering',
    query: {
      utm_campaign: 'shortlink',
    },
  },
  surest: {
    corporate: 'bind',
    query: {},
  },
  go: {
    corporate: 'uhc',
    query: {
      utm_medium: 'partner-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-05-en-ac-UHC-postcard',
      utm_content: 'dowhatyoulove-dowhatyoulove-shorturl',
    },
  },
  qorvo1: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'A-varA-printlink',
    },
  },
  qorvo2: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'B-varB-printlink',
    },
  },
  qorvo3: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'C-varC-printlink',
    },
  },
  mps1: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'A-varA-printlink',
    },
  },
  mps2: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'B-varB-printlink',
    },
  },
  mps3: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'C-varC-printlink',
    },
  },
  mortenson1: {
    corporate: 'mamortensoncompany',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-12-en-ac-mamortenson-mortenson',
      utm_content: 'dowhatyoulove-postcard-shortcode',
    },
  },
  travis1: {
    corporate: 'traviscounty',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-12-en-ac-traviscounty-traviscounty',
      utm_content: 'dowhatyoulove-postcard-shortcode',
    },
  },
  constellation1: {
    corporate: 'uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-12-en-ac-constellation-giftcard',
      utm_content: 'holidayincentive-postcard-shortcode',
    },
  },
  pebb1: {
    corporate: 'pebb',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2024-12-en-ac-pebb-fitbit',
      utm_content: 'holidayincentive-letter1-shortcode',
    },
  },
  pebb2: {
    corporate: 'pebb',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2024-12-en-ac-pebb-fitbit',
      utm_content: 'holidayincentive-letter2-shortcode',
    },
  },
  pebb3: {
    corporate: 'pebb',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2024-12-en-ac-pebb-fitbit',
      utm_content: 'holidayincentive-letter3-shortcode',
    },
  },
  cerris1: {
    corporate: 'cerris',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-cerris-launchletter',
      utm_content: 'welcometokaia-letter1-shortcode',
    },
  },
  cerris2: {
    corporate: 'cerris',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-cerris-launchletter',
      utm_content: 'welcometokaia-letter2-shortcode',
    },
  },
  cerris3: {
    corporate: 'cerris',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-cerris-launchletter',
      utm_content: 'welcometokaia-letter3-shortcode',
    },
  },
  bridge1: {
    corporate: 'bridgeinvestmentgroupholdings_umr',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-bridgeinvestmentgroup-launchletter',
      utm_content: 'welcometokaia-letter1-shortcode',
    },
  },
  bridge2: {
    corporate: 'bridgeinvestmentgroupholdings_umr',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-bridgeinvestmentgroup-launchletter',
      utm_content: 'welcometokaia-letter2-shortcode',
    },
  },
  bridge3: {
    corporate: 'bridgeinvestmentgroupholdings_umr',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-bridgeinvestmentgroup-launchletter',
      utm_content: 'welcometokaia-letter3-shortcode',
    },
  },
  eternalhealth1: {
    corporate: 'eternalhealth',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-eternalhealth-launchletter',
      utm_content: 'welcometokaia-letter1-shortcode',
    },
  },
  eternalhealth2: {
    corporate: 'eternalhealth',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-eternalhealth-launchletter',
      utm_content: 'welcometokaia-letter2-shortcode',
    },
  },
  eternalhealth3: {
    corporate: 'eternalhealth',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-es-ac-eternalhealth-launchletter',
      utm_content: 'welcometokaia-letter3-shortcode',
    },
  },
  sdog: {
    corporate: 'schooldistrictofgreenfield_uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-01-schooldistrictofgreenfield-giftcard',
      utm_content: 'kaiarewards-letter-shortcode',
    },
  },
  cards: {
    corporate: 'uhc',
    query: {
      utm_medium: 'client-workplace',
      utm_source: 'flashcards',
      utm_campaign: '2025-01-toolkit',
      utm_content: 'healthyhabits-cards-shortlink',
    },
  },
  now: {
    corporate: 'uhc',
    query: {
      utm_medium: 'client-workplace',
      utm_source: 'various',
      utm_campaign: '2025-01-toolkit',
      utm_content: 'now-shortlink',
    },
  },
  women: {
    corporate: 'uhc',
    query: {
      utm_medium: 'client-workplace',
      utm_source: 'various',
      utm_campaign: '2025-01-toolkit',
      utm_content: 'women-shortlink',
      variant: 'women',
    },
  },
  lamar1: {
    corporate: 'lamarconsolidatedindependent_sd',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-02-lamar-',
      utm_content: 'exercisematcontin---shortcode',
    },
  },
  valero1: {
    corporate: 'valero',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-valero-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  valero2: {
    corporate: 'valero',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-valero-',
      utm_content: 'exercisemat-nologoenv410-shortcode',
    },
  },
  suffolk1: {
    corporate: 'suffolkschoolsemployeeshealthplan_uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-02-suffolk-',
      utm_content: 'variant1---shortcode',
    },
  },
  suffolk2: {
    corporate: 'suffolkschoolsemployeeshealthplan_uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-02-suffolk-',
      utm_content: 'variant2---shortcode',
    },
  },
  holcim1: {
    corporate: 'holcimus',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-holcim-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  holcim2: {
    corporate: 'holcimus',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2025-03-holcim-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  greetings1: {
    corporate: 'americangreetings',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-all-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  shiseido1: {
    corporate: 'uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-shiseido-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  bridge4: {
    corporate: 'bridgeinvestmentgroupholdings_umr',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2025-03-bridge-',
      utm_content: 'voucher-410-shortcode',
    },
  },
  sound1: {
    corporate: 'soundinpatientphysicians',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2025-03-all-',
      utm_content: 'voucher-410-shortcode',
    },
  },
  first: {
    corporate: 'uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-firststudent-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  qorvo4: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-all-',
      utm_content: 'exercisemat-410-shortcode',
    },
  },
  qorvo6: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2025-09-all-',
      utm_content: 'voucher-410-shortcode',
    },
  },
  kiewit1: {
    corporate: 'peterkiewitsons',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-05-all-',
      utm_content: 'fitbit-x-shortcode',
    },
  },
  accent1: {
    corporate: 'uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-accentcare-',
      utm_content: 'fitbit-x-shortcode',
    },
  },
  fairfield1: {
    corporate: 'fairfieldcounty_uhc',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '04-01-2025-exercise-mat',
      utm_content: 'shortlink',
    },
  },
  valero3: {
    corporate: 'valero',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '05-13-2025-exercise-matpelvic-health',
      utm_content: 'shortlink',
      variant: 'women',
    },
  },
  wisconsin: {
    corporate: 'stateofwisconsin',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-03-various-',
      utm_content: 'base-universal-shortlink',
    },
  },
  well: {
    corporate: 'stateofwisconsin',
    query: {
      utm_medium: 'client-workplace',
      utm_source: 'various',
      utm_campaign: '2025-03-toolkit-',
      utm_content: 'all-universal-shortlink',
    },
  },
  palmbeach1: {
    corporate: 'palmbeachschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-04-all-',
      utm_content: 'fitbit-letter-shortcode',
    },
  },
  wha: {
    corporate: 'westernhealth',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-04-all-',
      utm_content: 'exercisemat-x-shortlink',
    },
  },
  fitbit: {
    corporate: null,
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: 'fitbit',
      utm_content: 'shortlink',
      landing: 'true',
    },
  },
  giftcard: {
    corporate: null,
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: 'giftcard',
      utm_content: 'shortlink',
      landing: 'true',
    },
  },
  mat: {
    corporate: null,
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: 'exeercisemat',
      utm_content: 'shortlink',
      landing: 'true',
    },
  },
  'mat-women': {
    corporate: null,
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: 'exeercisemat-pelvichealth',
      utm_content: 'shortlink',
      variant: 'women',
      landing: 'true',
    },
  },
  mps4: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'letter',
      utm_campaign: '2025-04-all',
      utm_content: 'fitbit-letter-shortcode',
    },
  },
}
