<template>
  <div class="footer-links">
    <div
      class="footer-content"
      :class="{ slim }"
    >
      <p class="footer-label kds-typography-title-medium">
        {{ $t('Kaia Health Software Inc.') }}
      </p>
      <DocumentViewer
        class="kds-typography-paragraph-large"
        :link="aTagWrapper($t('b2b_static_terms_conditions_link'), 'terms')"
      />
      <DocumentViewer
        class="kds-typography-paragraph-large"
        :link="aTagWrapper($t('b2b_static_privacy_policy_link'), 'privacy')"
      />
      <span class="privacy">
        <img
          src="@shared/assets/img/privacy_preferences.svg"
          alt="cookie icon"
          class="privacy-icon"
          @click.prevent="cookiePreferencesModalRef.open"
        />
        <a
          href="#"
          class="kds-typography-paragraph-large privacy"
          @click.prevent="cookiePreferencesModalRef.open"
          >{{ $t('cookie_dialog_open_preferences') }}</a
        >
        <CookiePreferencesModal ref="cookiePreferencesModalRef" />
      </span>
      <BaseModal
        can-click-outside
        :width="1000"
      >
        <template #trigger="{ open }">
          <span class="kds-typography-paragraph-large">
            <a
              href="#"
              @click.prevent="open"
            >
              {{ $t('b2b_static_acknowledgements_label') }}
            </a>
          </span>
        </template>
        <Acknowledgements />
      </BaseModal>
    </div>
  </div>
</template>
<script setup>
import DocumentViewer from '@shared/components/DocumentViewer.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import defineLanguage from '@shared/utils/defineLanguage.js'
import env from '@shared/env.js'
import BaseModal from '@shared/components/BaseModal.vue'
import Acknowledgements from '@shared/components/Acknowledgements.vue'
import CookiePreferencesModal from '@us/app/components/CookiePreferencesModal.vue'

defineProps({
  slim: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()

const lang = computed(() => store.getters.lang)

const cookiePreferencesModalRef = ref(null)

const aTagWrapper = (translation, type) => {
  const definedLang = defineLanguage(lang.value)
  const documentLang = env.isUs
    ? definedLang === 'es'
      ? definedLang
      : 'us'
    : definedLang
  const base = env.VITE_PUBLIC_PATH

  return `<a
          href="#"
          data-custom="false"
          data-link="${base}static/${documentLang}/${type}/index.html"
        >${translation}</a>`
}
</script>

<style lang="scss" scoped>
.footer-links {
  border-top: 1px solid $kds-color-line-subtle;

  .footer-content {
    display: flex;
    padding: $kds-space-2_5x;
    gap: $kds-space-2x;
    flex-direction: column;
    align-items: start;
    max-width: $size-large-x;
    margin: auto;

    .footer-label {
      width: 100%;
    }

    .privacy {
      display: flex;
      gap: $kds-space-1x;
      align-items: center;
    }
    .privacy-icon {
      vertical-align: middle;
    }

    @include media-up(md) {
      column-gap: $kds-space-3x;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    @include media-up(lg) {
      &:not(.slim) {
        justify-content: space-between;

        .footer-label {
          width: unset;
        }
      }
    }
  }
}
</style>
