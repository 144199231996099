import AbstractModel from './utils/abstractModel'
import types from './utils/types'

const CORPORATE_CHANNELS = {
  direct: 'direct',
  virgin_pulse: 'virgin_pulse',
  surest: 'surest',
  solera: 'solera',
  fully_insured: 'fully_insured',
  de_insurers: 'de_insurers',
  providence: 'providence',
  uhcmedsupp: 'uhcmedsupp',
  westernhealth: 'westernhealth',
  umr: 'umr',
  uhg: 'uhg',
  optumcare: 'optumcare',
  uhcvpt: 'uhcvpt',
  converge: 'converge',
  optum: 'optum',
  uhcnational: 'uhcnational',
  demo: 'demo',
  referralprograms: 'referralprograms',
}

class Corporate extends AbstractModel {
  // This model is intended to be used whenever we're dealing with a corporate object rendered by the API

  /**
   * @param {string}
   * @readonly
   */
  additionalLegalDocFile

  /**
   * @type {array}
   * @readonly
   */
  alternateTitles

  /**
   * @type {array.<string>}
   * @readonly
   */
  corporateTypes

  /**
   * @type {string}
   * @readonly
   */
  corporateChannel

  /**
   * not coming from API. Can be used in order to customize the title of the corporate in some flow (e.g. dropdown...)
   * @param {string | undefined}
   */
  customTitle

  /**
   * @type {number}
   * @readonly
   */
  id

  /**
   * @type {boolean}
   * @readonly
   */
  isCustomer

  /**
   * @type {boolean}
   * @readonly
   */
  isPrivateInsurance

  /**
   * @type {boolean}
   * @readonly
   */
  isPublicInsurance

  /**
   * @type {string}
   * @readonly
   */
  key

  /**
   * @param {string}
   * @readonly
   */
  logoFile

  /**
   * @param {string}
   * @readonly
   */
  preferredSignupMethod

  /**
   * @param {string}
   * @readonly
   */
  prescriptionUploadMode

  /**
   * @type {object, null}
   * @readonly
   */
  primaryAddress

  /**
   * @param {boolean}
   * @readonly
   */
  showExtendedOnboarding

  /**
   * @param {string}
   * @readonly
   */
  signupInfoPageUrl

  /**
   * @type {string}
   * @readonly
   */
  title

  /**
   * @param {Object.<string, string>}
   * @readonly
   */
  translations

  /**
   * @param {string}
   * @readonly
   */
  virginPulseSponsorId

  constructor(json) {
    super(json)
    this.id = json.id
    this.title = json.title
    this.customTitle = json.custom_title || json.title
    this.isCustomer = json.is_customer || false
    this.key = json.key
    this.isPublicInsurance = json.is_public_insurance || false
    this.isPrivateInsurance = json.is_private_insurance || false
    this.corporateTypes = json.corporate_types || []
    this.corporateChannel = json.corporate_channel
    this.alternateTitles = json.alternate_titles || []
    this.preferredSignupMethod = json.preferred_signup_method
    this.additionalLegalDocFile =
      json.additional_legal_doc_file !== null &&
      json.additional_legal_doc_file?.length > 0
        ? json.additional_legal_doc_file
        : null
    this.logoFile = json.logo_file
    this.showExtendedOnboarding = json.show_extended_onboarding
    this.primaryAddress = json.primary_address || {}
    this.translations = json.translations
    this.signupInfoPageUrl = json.signup_info_page_url
    this.prescriptionUploadMode = json.prescription_upload_mode
    this.virginPulseSponsorId = json.virgin_pulse_sponsor_id

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('title', [types.STRING])
    this.assertType('customTitle', [types.STRING, types.NULL])
    this.assertType('isCustomer', [types.BOOLEAN])
    this.assertType('key', [types.STRING])
    this.assertType('isPublicInsurance', [types.BOOLEAN])
    this.assertType('isPrivateInsurance', [types.BOOLEAN])
    this.assertType('corporateTypes', [types.ARRAY])
    this.assertType('corporateChannel', [types.STRING, types.NULL])
    this.assertType('alternateTitles', [types.ARRAY])
    this.assertType('preferredSignupMethod', [types.STRING, types.NULL])
    this.assertType('additionalLegalDocFile', [types.STRING, types.NULL])
    this.assertType('logoFile', [types.STRING, types.NULL])
    this.assertType('showExtendedOnboarding', [types.BOOLEAN, types.NULL])
    this.assertType('translations', [types.JSON, types.NULL])
    this.assertType('signupInfoPageUrl', [types.STRING, types.NULL])
    this.assertType('prescriptionUploadMode', [types.STRING, types.NULL])
    this.assertType('primaryAddress', [types.JSON])
    this.assertType('virginPulseSponsorId', [types.STRING, types.NULL])
  }

  /**
   * Check if the corporate is a demo corporate
   * @returns {boolean}
   * @readonly
   */
  get isDemo() {
    return !this.isPublicInsurance && !this.isPrivateInsurance
  }

  /**
   * Type of insurance of the corporate
   * @returns {'private' | 'public' | 'not_set'}
   * @readonly
   */
  get insuranceType() {
    if (this.isPrivateInsurance && !this.isPublicInsurance) {
      return 'private'
    } else if (!this.isPrivateInsurance && this.isPublicInsurance) {
      return 'public'
    } else {
      return 'not_set'
    }
  }

  /**
   * check if additional legal doc file should be rendered
   * @returns {boolean}
   * @readonly
   */
  get hasAdditionalLegalDoc() {
    return this.additionalLegalDocFile !== null
  }
}

export default Corporate
