<template>
  <div>
    <FullPagePreloader v-if="showPreloader" />
    <div
      v-else
      class="kds-main-wrapper content"
    >
      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('assessment_completion_title')"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('assessment_completion_description')"
        />
      </div>
      <BaseButton
        v-if="deepLink"
        :text="$t('b2b_goto_app_btn')"
        @click="redirectToApp"
      />
    </div>
  </div>
</template>

<script setup>
import { isFromApp } from '@shared/helpers/isFromApp'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import { sessionStorage } from '@shared/helpers/storage'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import BaseButton from '@shared/components/BaseButton.vue'

const deepLink = ref('')
const showPreloader = ref(false)

const showFullPagePreloader = (shouldShow = true) => {
  showPreloader.value = shouldShow
}

const redirectToApp = () => {
  window.location.href = deepLink.value
}

onMounted(async () => {
  if (isFromApp()) {
    const completedInitialAssessment =
      sessionStorage.getItem('assessment:previousQuestionnaire') === 'self_test'

    showFullPagePreloader()
    deepLink.value = await assembleKaiaAppDeepLink(
      'trigger/assessment_completed',
      { ...(completedInitialAssessment ? { type: 'initial' } : {}) },
    )
    setTimeout(() => {
      showFullPagePreloader(false)
    }, 1000)

    redirectToApp()
  }
})

onBeforeUnmount(() => {
  showFullPagePreloader(false)
})

showFullPagePreloader(isFromApp())
</script>

<style lang="scss" scoped>
.content {
  padding-top: 24px;
}
</style>
