<template>
  <BaseModal
    ref="modalRef"
    :width="1000"
  >
    <div class="kds-main-wrapper cookie-preferences-modal">
      <DocumentViewer
        class="message kds-typography-paragraph-large"
        :link="$t('cookie_preferences_message')"
      />
      <div class="cookie-accordion">
        <div
          v-for="(
            {
              interactable,
              expanded,
              toggleExpanded,
              title,
              description,
              accepted,
              setAccepted,
            },
            index
          ) in items"
          :key="index"
          class="cookie-accordion-item"
          :class="{
            interactable,
          }"
          @click="toggleExpanded"
        >
          <div
            class="cookie-accordion-item-trigger"
            :class="{
              expanded,
              interactable,
            }"
          >
            <ChevronDown />
          </div>

          <div class="item-content">
            <div class="kds-typography-ui-strong-large">{{ title }}</div>
            <VerticalShowHideTransition :visible="expanded">
              <span class="kds-typography-ui-subtle-medium">
                {{ description }}
              </span>
            </VerticalShowHideTransition>
          </div>
          <ToggleField
            :model-value="accepted"
            :disabled="!interactable"
            @update:model-value="(value) => setAccepted(value)"
          />
        </div>
      </div>
      <div class="actions">
        <BaseButton
          variant="primary"
          size="small"
          @click="confirm"
        >
          {{ $t('cookie_preferences_confirm') }}
        </BaseButton>
        <BaseButton
          variant="ghost"
          size="small"
          @click="rejectAll"
        >
          <span class="action_reject_all">
            {{ $t('cookie_preferences_reject_all') }}
          </span>
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'petite-vue-i18n'
import usePersistedConfigStore from '@shared/store/usePersistedConfigStore.js'
import BaseButton from '@shared/components/BaseButton.vue'
import BaseModal from '@shared/components/BaseModal.vue'
import ChevronDown from '@shared/assets/icon/ChevronDown.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'
import ToggleField from '@shared/components/form/ToggleField.vue'
import VerticalShowHideTransition from '@shared/components/VerticalShowHideTransition.vue'

const { t } = useI18n()
const persistedConfigStore = usePersistedConfigStore()

const modalRef = ref(null)
const performanceTrackingEnabled = ref(null)
const necessaryCookiesExpanded = ref(null)
const performanceCookiesExpanded = ref(null)

const items = ref([
  {
    interactable: false,
    title: computed(() => t('cookie_preferences_necessary_cookies_title')),
    description: computed(() =>
      t('cookie_preferences_necessary_cookies_description'),
    ),
    accepted: true,
    setAccepted: () => {
      // Necessary cookies cannot be toggled
    },
    expanded: necessaryCookiesExpanded,
    toggleExpanded: () => {
      necessaryCookiesExpanded.value = !necessaryCookiesExpanded.value
    },
  },
  {
    interactable: true,
    title: computed(() => t('cookie_preferences_performance_cookies_title')),
    description: computed(() =>
      t('cookie_preferences_performance_cookies_description'),
    ),
    accepted: performanceTrackingEnabled,
    setAccepted: (accepted) => {
      performanceTrackingEnabled.value = accepted
    },
    expanded: performanceCookiesExpanded,
    toggleExpanded: () => {
      performanceCookiesExpanded.value = !performanceCookiesExpanded.value
    },
  },
])

const open = () => {
  // reload state
  performanceTrackingEnabled.value =
    persistedConfigStore.performanceTrackingEnabled
  necessaryCookiesExpanded.value = false
  performanceCookiesExpanded.value = false
  modalRef.value.open()
}

const confirm = () => {
  persistedConfigStore.performanceTrackingEnabled =
    performanceTrackingEnabled.value
  modalRef.value.close()
}

const rejectAll = () => {
  persistedConfigStore.performanceTrackingEnabled = false
  modalRef.value.close()
}

defineExpose({ open })
</script>

<style lang="scss" scoped>
.cookie-preferences-modal {
  padding: $kds-space-1x $kds-space-2x;

  .message {
    :global(a) {
      color: $kds-color-text-interactive;
    }
  }

  .cookie-accordion {
    @include flex-container(column, space-between, md, stretch);

    .cookie-accordion-item {
      @include flex-container(row, space-between, md, center);
      color: $kds-color-text-on-interactive-disabled;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &.interactable {
        color: $kds-color-text-interactive;
      }

      .cookie-accordion-item-trigger {
        @include margin(0);
        width: 16px;
        height: 16px;
        // overwrite the icons color
        filter: invert(12%) sepia(51%) saturate(2399%) hue-rotate(202deg)
          brightness(96%) contrast(95%) opacity(0.5);
        align-self: center;
        transition: transform 0.25s linear;
        transform: rotate(-90deg);

        &.expanded {
          transform: rotate(0deg);
        }

        &.interactable {
          filter: unset;
        }
      }

      .item-content {
        flex-grow: 1;
      }
    }
  }

  .actions {
    @include flex-container(column, space-between, sm, center);

    .action_reject_all {
      text-decoration: underline;
    }
  }
}
</style>
